import { AppConfig } from "../config.ts";
import { Colours, isValidColour } from "./util/colours.ts";

export const PageWidths = ["slim", "default", "wide", "fill"] as const;
export type PageWidths = typeof PageWidths[number];
export const ColourSchemes = ["light", "dark", "auto"] as const;
export type ColourSchemes = typeof ColourSchemes[number];

function isValidColourScheme(scheme: string | any): ColourSchemes | false {
    return ColourSchemes.includes(scheme as any) ? scheme : false;
}

type ThemeManagerListener = 
    (accent: Colours, scheme: ColourSchemes, neutral: Colours, width: PageWidths) => void;

    
async function applyDocumentTheme(accent: Colours, scheme: ColourSchemes, neutral: Colours, width: PageWidths) {
    let doc = document.documentElement;
    let className = `${accent} ${scheme} m-${neutral} pagewidth-${width}`;
    try {
        await document.startViewTransition(() => {
            doc.className = className + ' noTransition';
        }).finished
    } catch(e) {
        doc.className = className + ' noTransition';
        doc.offsetHeight; // force the browser to recalculate the styles
    } finally {
        doc.className = className;
    }
    
}

class ThemeManager {
    listeners = [ applyDocumentTheme ] as (ThemeManagerListener)[];
    on(fn: ThemeManagerListener) { this.listeners.push(fn); }

    constructor() {
        this.apply();
    }

    apply() {
        let accent = this.accent;
        let scheme = this.scheme;
        let neutral = this.neutral;
        let width = this.width;
        this.listeners.forEach(fn => 
            setTimeout(() => fn(accent, scheme, neutral, width),0)
        );
    }


    get accent() {
        if (AppConfig.ENV == "crm-uat") return "orange";
        return isValidColour(localStorage.getItem("accent")) || "cyan";
    }
    set accent(color: Colours) {
        localStorage.setItem("accent", color);
        this.apply();
    }

    get neutral() {
        return isValidColour(localStorage.getItem("neutral")) || "slate";
    }
    set neutral(color: Colours) {
        localStorage.setItem("neutral", color);
        this.apply();
    }


    get scheme() {
        if (AppConfig.ENV == "crm-uat") 
            return "dark" 
        return isValidColourScheme(localStorage.getItem("scheme")) || "auto";
    }
    set scheme(scheme: ColourSchemes) {
        localStorage.setItem("scheme", scheme);
        this.apply();
    }

    get width() {
        let width = localStorage.getItem("width") as PageWidths;
        if (!PageWidths.includes(width)) return "default";
        else return width;
    }
    set width(width: PageWidths) {
        localStorage.setItem("width", width);
        this.apply();
    }
}
export const themeManager = new ThemeManager();